var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.student
    ? _c(
        "div",
        { key: `key_${_vm.key}`, staticClass: "kt-subheader kt-grid__item" },
        [
          _c("div", { staticClass: "kt-container kt-container--fluid" }, [
            _c(
              "div",
              { staticClass: "kt-subheader__main" },
              [
                _c("h3", { staticClass: "kt-subheader__title" }, [
                  !_vm.encryptionEnabled
                    ? _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.$_panelMixins_openPanelForStudent(
                                _vm.student
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "UserAvatar",
                            {
                              staticClass: "kt-media mr-2",
                              staticStyle: { width: "1.75rem" },
                              attrs: {
                                rounded: true,
                                "avatar-user": _vm.student,
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "kt-media kt-media--sm" },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "kt-media kt-media--lg kt-media--success",
                                      staticStyle: { width: "1.75rem" },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            height: "1.75rem",
                                            "font-size": ".75rem",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.initials) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(
                            " " +
                              _vm._s(_vm.student.lastName) +
                              ", " +
                              _vm._s(_vm.student.firstName) +
                              " "
                          ),
                        ],
                        1
                      )
                    : _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.$_panelMixins_openPanelForStudent(
                                _vm.student
                              )
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "kt-media kt-media--sm" }, [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "kt-media kt-media--lg kt-media--success",
                                staticStyle: { width: "1.75rem" },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      height: "1.75rem",
                                      "font-size": ".75rem",
                                    },
                                  },
                                  [_vm._v(" " + _vm._s(_vm.initials) + " ")]
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(
                            " " + _vm._s(_vm.student.maskedStudentName) + " "
                          ),
                        ]
                      ),
                ]),
                _c("span", {
                  staticClass:
                    "kt-subheader__separator kt-subheader__separator--v ml-3",
                }),
                _vm.showMarkingPeriodPicker
                  ? _c("SubHeaderMarkingPeriodPicker")
                  : _vm._e(),
                _vm._t("left-buttons"),
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "kt-subheader__toolbar" },
              [
                _vm._t("buttons"),
                _c("div", { staticClass: "kt-subheader__wrapper" }, [
                  _vm.$_userMixins_isSchoolUser
                    ? _c(
                        "div",
                        [
                          _c(
                            "a",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover.top",
                                  value: `Create Anecdotal`,
                                  expression: "`Create Anecdotal`",
                                  modifiers: { hover: true, top: true },
                                },
                              ],
                              staticClass:
                                "btn btn-icon btn-circle btn-label-twitter",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  return _vm.newAnecdotal.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "la la-file-text",
                                staticStyle: {
                                  "font-size": "1.4rem !important",
                                },
                              }),
                            ]
                          ),
                          _vm.hasBadges
                            ? _c(
                                "a",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.top",
                                      value: `Add Award / Badge`,
                                      expression: "`Add Award / Badge`",
                                      modifiers: { hover: true, top: true },
                                    },
                                  ],
                                  staticClass:
                                    "btn btn-icon btn-circle btn-label-linkedin",
                                  staticStyle: { "margin-left": "12px" },
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.newStudentBadge.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "la la-smile-o",
                                    staticStyle: {
                                      "font-size": "1.5rem !important",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          !_vm.isHomeUser
                            ? _c(
                                "b-dropdown",
                                {
                                  staticClass: "m-1",
                                  attrs: {
                                    "toggle-class": "px-0",
                                    variant: "none",
                                    "no-caret": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "button-content",
                                        fn: function () {
                                          return [
                                            _c(
                                              "button",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-tooltip",
                                                    rawName:
                                                      "v-b-tooltip.hover.top",
                                                    value: `Create Message`,
                                                    expression:
                                                      "`Create Message`",
                                                    modifiers: {
                                                      hover: true,
                                                      top: true,
                                                    },
                                                  },
                                                ],
                                                staticClass:
                                                  "btn btn-icon btn-circle btn-label-google",
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "la la-comments",
                                                  staticStyle: {
                                                    "font-size":
                                                      "1.5rem !important",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    1792617377
                                  ),
                                },
                                [
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: {
                                        variant: "none",
                                        "button-class": "dropdown-item",
                                        "link-class": "kt-link",
                                      },
                                      on: {
                                        click: _vm.toggleCreateMessageToStudent,
                                      },
                                    },
                                    [
                                      _c("SVGIcon", {
                                        staticClass:
                                          "kt-svg-icon kt-svg-icon--sm mr-2",
                                        attrs: { name: "communication" },
                                      }),
                                      _vm._v(" Message Student "),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-dropdown-item",
                                    {
                                      attrs: {
                                        variant: "none",
                                        "button-class": "dropdown-item",
                                        "link-class": "kt-link",
                                      },
                                      on: {
                                        click:
                                          _vm.toggleCreateMessageToGuardian,
                                      },
                                    },
                                    [
                                      _c("SVGIcon", {
                                        staticClass:
                                          "kt-svg-icon kt-svg-icon--sm mr-2",
                                        attrs: { name: "guardians" },
                                      }),
                                      _vm._v(" Message Guardian "),
                                    ],
                                    1
                                  ),
                                  _vm._t("toolbar-dropdown"),
                                ],
                                2
                              )
                            : _vm._e(),
                          _c("toggle-button", {
                            directives: [
                              {
                                name: "b-tooltip",
                                rawName: "v-b-tooltip.hover.topleft",
                                modifiers: { hover: true, topleft: true },
                              },
                            ],
                            staticClass: "m-2 ml-3",
                            attrs: {
                              height: 28,
                              width: 56,
                              "font-size": 18,
                              title: _vm.forceStudentView
                                ? "Show School View"
                                : "Show Home View",
                              labels: { checked: "🏠", unchecked: "📚" },
                            },
                            model: {
                              value: _vm.forceStudentView,
                              callback: function ($$v) {
                                _vm.forceStudentView = $$v
                              },
                              expression: "forceStudentView",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ],
              2
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }